import { useEffect, useRef, useState } from 'react';
import config from '~/config';
import { waitUntilResolvedOrTimeout } from './utils';

interface IFingerprintJS {
	get: () => Promise<{ requestId: string; visitorId: string }>;
}

const loadFP = async () => {
	return import(
		/* @vite-ignore */
		`https://fp.kopromos.com.au/web/v3/${config.fingerprintApiKey}`
	).then(FingerprintJS => {
		return FingerprintJS.load({
			endpoint: [
				'https://fp.kopromos.com.au',
				// Fallback endpoint
				FingerprintJS.defaultEndpoint,
			],
		});
	});
};

const useFingerprint = (): {
	getVisitorId: () => Promise<{ requestId: string; visitorId: string }>;
} => {
	const fingerPrintRef = useRef<IFingerprintJS>();

	useEffect(() => {
		if (config.useFingerprint) {
			loadFP().then(fp => {
				(fingerPrintRef as any).current = fp;
			});
		}
	}, []);

	const getVisitorId = async () => {
		const blankResponse = {
			visitorId: '',
			requestId: '',
		};

		if (!config.useFingerprint) {
			return blankResponse;
		}

		const visitorIdFromStorage = sessionStorage.getItem('ko.visitorId');

		if (visitorIdFromStorage) {
			return {
				visitorId: visitorIdFromStorage,
				requestId: sessionStorage.getItem('ko.requestId') || '',
			};
		}

		try {
			if (!fingerPrintRef.current) {
				fingerPrintRef.current = await waitUntilResolvedOrTimeout<IFingerprintJS>(
					loadFP(),
					5000,
				);
			}

			const response = await fingerPrintRef.current.get();

			sessionStorage.setItem('ko.visitorId', response.visitorId);
			sessionStorage.setItem('ko.requestId', response.requestId);

			return {
				visitorId: response.visitorId,
				requestId: response.requestId,
			};
		} catch (e) {
			return blankResponse;
		}
	};

	return {
		getVisitorId,
	};
};

export default useFingerprint;
